import { Space, Table, Pagination, Tag, Modal, Flex, Button } from "antd";
import { useState, useEffect } from 'react';
import { fetchTagPosts, fetchTagRewards } from "../../api/community";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import { useDebouncedCallback } from "use-debounce";
import RewardEditModalView from "./reward_edit_modal_view";
import { Typography } from "antd";
import PostReview from "./post_review";
import UserInfoView from "./user_info_view";
import PostLikesView from "./post_like_users_view";

const RewardPostsModalView = ({ open, tag, onClose, toRewards }) => {
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={text} showWallet={true} />
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => {
                return (
                    <Space size={4}>
                        <Tag color={String(text.name).toLowerCase() === 'post' ? 'cyan' : 'blue'} style={{ margin: 0 }}>{text.name}</Tag>
                        {
                            (record.reward && parseFloat(record.reward.count) > 0 && record.reward.id) && (
                                <Tag color="#f50" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                                    <img alt='post reward' src={PrizeImg} style={{ width: 16, height: 'auto', marginRight: 2 }} />
                                    <b>Prize</b>
                                </Tag>
                            )
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 150 }}>
                        <div className="community-content">
                            <RichText content={record.title ? record.title : record.content} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Topic',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, _) => {
                return <div style={{ maxWidth: 200 }}>{text.map(item => item.name).filter(item => item).map(item => String(item).indexOf('#') === 0 ? item : `#${item}`).join(', ')}</div>;
            },
        },
        {
            title: 'Likes',
            dataIndex: 'agreeCount',
            key: 'agreeCount',
            render: (text, record) => {
                if (parseFloat(text) <= 0) return '-';
                return <Button type="link" onClick={() => toShowLikes(record)}><b>{text}</b></Button>
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Comments',
            dataIndex: 'commentCount',
            key: 'commentCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return text;
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Reward Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.rewardWinner) {
                    return <Typography.Text type="success"><b>SENT {record.rewardCount}</b></Typography.Text>;
                }
                return '-';
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review Post</Button>
                    </Button.Group>
                )
            },
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({
        sort: 'Id',
    });
    const [selectedRows, setSelectedRows] = useState();
    const [rewardUsers, setRewardUsers] = useState([]);
    const [openEditRewardModal, setOpenEditRewardModal] = useState(false);
    const [openPostReviewDrawer, setOpenPostReviewDrawer] = useState(false);
    const [openPostLikesDrawer, setOpenPostLikesDrawer] = useState(false);
    const [currPost, setCurrPost] = useState();

    useEffect(() => {
        setSelectedRows(null);
        setRewardUsers([]);
        if (tag && open) {
            setPageNum(1);
            fetchData(1, pageSize, searchParams);
            fetchUsers();
        }
    }, [tag, open]);

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'tag': tag,
            ...search,
        };
        fetchTagPosts(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list.filter(item => !item.user.email.includes('admin')).map(item => ({...item, resource: item.resources.map(e => e.url).join(',')})));
            }
        }).finally(() => setLoading(false))
    }

    const fetchUsers = () => {
        let params = {
            'page.disable': true,
            'tag': tag,
        };
        fetchTagRewards(params).then(res => {
            if (res.list) {
                setRewardUsers(res.list);
            }
        })
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onTabChange = (pagination, filters, sorter, extra) => {
        let orderValue = 'Id';
        if (sorter.order) {
            if (sorter.field === 'agreeCount') {
                orderValue = 'LikeCount'
            } else if (sorter.field === 'commentCount') {
                orderValue = 'CommentCount';
            }

        }
        onSearchChange('sort', orderValue);
    }

    const editHandle = () => {
        setOpenEditRewardModal(true);
    }

    const reviewHandle = (item) => {
        setCurrPost(item);
        setOpenPostReviewDrawer(true);
    }

    const toShowLikes = (record) => {
        setCurrPost(record);
        setOpenPostLikesDrawer(true);
    }

    if (!tag) return <div />;

    return (
        <>
            <Modal
                maskClosable
                open={open}
                footer={null}
                width={1200}
                onCancel={onClose}
                title='Related Posts'>
                <div className='main-wrapper'>
                    <Flex justify="space-between" className="cnt-item" style={{ marginTop: 10 }}>
                        <Button disabled={!selectedRows || selectedRows.length <= 0} type="primary" onClick={editHandle}>Send Rewards</Button>
                        <Button onClick={toRewards}>Check Reward Records</Button>
                    </Flex>
                    <div className='cnt-item' style={{ marginTop: 10 }}>
                        <Table
                            scroll={{ x: true }}
                            loading={loading}
                            rowKey={(record) => record.id}
                            rowClassName={'table-row'}
                            size='small'
                            dataSource={tabData}
                            columns={tabCols}
                            pagination={false}
                            rowSelection={{
                                selectedRowKeys: (selectedRows ?? []).map(item => item.id),
                                getCheckboxProps: (record) => ({
                                    disabled: rewardUsers.map(item => item.user.userId).includes(record.user.userId),
                                }),
                                onChange: (keys, rows) => {
                                    setSelectedRows(rows);
                                }
                            }}
                            onChange={onTabChange} />
                    </div>
                    <div className='footer-part cnt-item'>
                        <Pagination
                            total={total}
                            showTotal={(total) => `total ${total}`}
                            current={pageNum}
                            pageSize={pageSize}
                            showSizeChanger={true}
                            pageSizeOptions={[10, 20, 30, 50]}
                            onChange={(page, size) => pageChange(page, size)}
                        />
                    </div>
                </div>
            </Modal>
            <RewardEditModalView
                open={openEditRewardModal}
                onClose={() => setOpenEditRewardModal(false)}
                onUpdate={() => {
                    setSelectedRows(null);
                    fetchData(pageNum, pageSize, searchParams);
                    fetchUsers();
                }}
                users={(selectedRows ?? []).map(item => ({
                    userId: item.user.userId,
                    user: item.user,
                    postId: item.id,
                    txId: null,
                    tag: tag,
                    symbol: null,
                    amount: null,
                }))} />
            <PostReview
                from="reward"
                post={currPost}
                canEdit={false}
                open={openPostReviewDrawer}
                onClose={() => setOpenPostReviewDrawer(false)} />

            <PostLikesView
                post={currPost}
                open={openPostLikesDrawer}
                onClose={() => setOpenPostLikesDrawer(false)} />
        </>

    )
}

export default RewardPostsModalView;