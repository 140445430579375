import { useEffect, useState } from 'react'
import { Table, Pagination, Flex, Input, Space, Select, DatePicker, Tag, Typography } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { fetchPointsHistory } from '../../api/points';
import { conversionUtcDate } from '../../utils/comm';
import dayjs from 'dayjs';
import UserInfoView from '../../components/community/user_info_view';

const PointRecordsScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({
        userId: '',
        type: '',
        start: '',
        end: '',
    });

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                if(!text) return '-';
                return <span style={{fontSize: 12, color: '#afafaf'}}>{conversionUtcDate(text, null, true)}</span>
            }
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                return <UserInfoView user={record.user} showWallet />;
            }
        },
        {
            title: 'Username/UserId',
            dataIndex: 'username',
            key: 'username',
            render: (_, record) => {
                return (
                    <div>
                        <Typography.Text copyable style={{ fontSize: 12, color: '#999' }}><b>{record.user.username}</b></Typography.Text><br />
                        <Typography.Text copyable style={{ fontSize: 12, color: '#999' }}>{record.userId}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'activityType',
            key: 'activityType',
            render: (text, _,) => {
                return <Tag>{text}</Tag>
            },
        },
        {
            title: 'Points',
            dataIndex: 'point',
            key: 'point',
            render: (_, record) => {
                if(String(record.direction).toLowerCase() === 'in') {
                    return <Tag color='green'>+{record.point}</Tag>
                }
                return <Tag color='red'>-{record.point}</Tag>
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, _,) => {
                return <div style={{color: '#afafaf', fontSize: 12}}>{text}</div>
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        getRecords(pageNum, pageSize, searchParams);
    }, [])

    const getRecords = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                ...search
            }
            setLoading(true);
            fetchPointsHistory(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getRecords(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getRecords(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <div />
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('userId', evt.target.value)}
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'CheckIn', value: 0 },
                                { key: 2, label: 'BeginnerTasks', value: 1 },
                                { key: 3, label: 'DailyTasks', value: 2 },
                                { key: 4, label: 'Redeem', value: 3 },
                            ]}
                            placeholder='status'
                            onChange={value => onSearchChange('type', value)}
                            allowClear
                        />
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default PointRecordsScreen;