import request from '../utils/request';

export function fetchDiscovers(params) {
    return request.get('/discover', params);
}

export function createDiscover(params) {
    return request.post('/discover/create', params);
}

export function updateDiscover(params) {
    return request.patch('/discover/edit', params);
}

export function deleteDiscover(id) {
    return request.dataDel(`/post/delete?ids=${id}`);
}