import React, { useMemo } from "react";
import {
    Route,
    HashRouter,
    Routes,
    useLocation,
    Navigate,
    useOutlet,
} from "react-router-dom";
import LoginPanel from "../screens/login";
import MainLayout from "../layout/mainlayout";
import { getCookie } from "../utils/cookie";
import BannerScreen from "../screens/banner";
import BannerDetailScreen from "../screens/banner/detail";
import DiscoverScreen from "../screens/discover";
import DiscoverDetailScreen from "../screens/discover/detail";
import DailyScreen from "../screens/daily";
import CourseScreen from "../screens/course";
import CourseDetailScreen from "../screens/course/detail";
import NewsScreen from "../screens/news";
import NewsDetailScreen from "../screens/news/detail";
import DailyDetailScreen from "../screens/daily/detail";
import CommunityDetailScreen from "../screens/community/detail";
import DailyQaScreen from "../screens/daily/qa_index";
import UsersScreen from "../screens/users";
import UserRecommendScreen from "../screens/users/recommend_user";
import TopicScreen from "../screens/community/bounty_topic";
import KeepAlive from "keepalive-for-react";
import DashboardScreen from "../screens/dashboard";
import SystemSettingScreen from "../screens/system";
import ReportScreen from "../screens/community/report";
import CommentsScreen from "../screens/community/comment";
import PointProductsScreen from "../screens/points/products";
import PointBalanceScreen from "../screens/points/balances";
import UserLabelScreen from "../screens/users/labels";
import HomeArticleScreen from "../screens/home/hot_article";
import HomeTopicScreen from "../screens/home/hot_topic";
import PointRecordsScreen from "../screens/points/history";
import AllTagsScreen from "../screens/community/topic_tags";
import TagDetailScreen from "../screens/community/tagdetail";
import ContentTagsScreen from "../screens/community/content_tags";
import UserPostsScreen from "../screens/community/user_posts";
import UserArticlesScreen from "../screens/community/user_articles";
import AdminPostsScreen from "../screens/community/admin_posts";
import AdminArticlesScreen from "../screens/community/admin_articles";

export const MainLayoutUrl = {
    dashboard: {
        index: {
            path: "/dashboard",
            selectedKey: "dashboard",
            element: <DashboardScreen />,
        },
    },
    banner: {
        index: {
            path: "/banners",
            selectedKey: "banner",
            element: <BannerScreen />
        },
        item: {
            path: "/banners/:id",
            key: 'bannerItem',
            selectedKey: "banner",
            element: <BannerDetailScreen />
        },
    },
    homepage: {
        hotarticle: {
            index: {
                path: "/hotarticle",
                selectedKey: "hotarticle",
                needCache: true,
                element: <HomeArticleScreen />
            },
        },
        hottag: {
            index: {
                path: "/hottag",
                selectedKey: "hottag",
                needCache: true,
                element: <HomeTopicScreen />
            },
        },
    },
    discover: {
        index: {
            path: "/discover",
            selectedKey: "discover",
            needCache: true,
            element: <DiscoverScreen />
        },
        item: {
            path: "/discover/:id",
            key: "discoverItem",
            selectedKey: "discover",
            element: <DiscoverDetailScreen />
        },
    },
    daily: {
        knowledge: {
            index: {
                path: "/knowledge",
                selectedKey: "knowledge",
                element: <DailyScreen />
            },
            item: {
                path: "/knowledge/:id",
                key: "dailyItem",
                selectedKey: "knowledge",
                element: <DailyDetailScreen />
            },
        },
        question: {
            index: {
                path: "/question",
                selectedKey: "question",
                element: <DailyQaScreen />
            },
        },
    },
    course: {
        index: {
            path: "/courses",
            selectedKey: "course",
            element: <CourseScreen />
        },
        item: {
            path: "/courses/:id",
            key: "courseItem",
            selectedKey: "course",
            element: <CourseDetailScreen />
        },
    },
    news: {
        index: {
            path: "/news",
            selectedKey: "news",
            element: <NewsScreen />
        },
        item: {
            path: "/news/:id",
            key: "newsItem",
            selectedKey: "news",
            element: <NewsDetailScreen />
        },
    },
    community: {
        topic: {
            index: {
                path: "/topic",
                selectedKey: "topic",
                element: <TopicScreen />
            },
        },
        post: {
            userpost: {
                path: "/userpost",
                selectedKey: "userpost",
                needCache: true,
                element: <UserPostsScreen />
            },
            userarticle: {
                path: "/userarticle",
                selectedKey: "userarticle",
                needCache: true,
                element: <UserArticlesScreen />
            },
            adminpost: {
                path: "/adminpost",
                selectedKey: "adminpost",
                needCache: true,
                element: <AdminPostsScreen />
            },
            adminarticle: {
                path: "/adminarticle",
                selectedKey: "adminarticle",
                needCache: true,
                element: <AdminArticlesScreen />
            },
            item: {
                path: "/post/:id",
                key: "postItem",
                selectedKey: "post",
                element: <CommunityDetailScreen />
            },
        },
        report: {
            index: {
                path: "/report",
                selectedKey: "report",
                element: <ReportScreen />
            },
        },
        comment: {
            index: {
                path: "/comment",
                selectedKey: "comment",
                needCache: true,
                element: <CommentsScreen />
            },
        },
        tags: {
            index: {
                path: "/alltags",
                selectedKey: "tags",
                needCache: true,
                element: <AllTagsScreen />
            },
            item: {
                path: "/alltags/:id",
                key: 'tagItem',
                selectedKey: "tags",
                element: <TagDetailScreen />
            },
        },
        clabels: {
            index: {
                path: "/clabels",
                selectedKey: "clabels",
                needCache: true,
                element: <ContentTagsScreen />
            },
        },
    },
    users: {
        all: {
            index: {
                path: "/all",
                selectedKey: "all",
                needCache: true,
                element: <UsersScreen />
            },
        },
        recommenduser: {
            index: {
                path: "/recommenduser",
                selectedKey: "recommenduser",
                element: <UserRecommendScreen />
            },
        },
        ulabels: {
            index: {
                path: "/ulabels",
                selectedKey: "ulabels",
                element: <UserLabelScreen />
            },
        },
    },
    points: {
        products: {
            index: {
                path: "/products",
                selectedKey: "products",
                needCache: true,
                element: <PointProductsScreen />
            },
        },
        balance: {
            index: {
                path: "/balance",
                selectedKey: "balance",
                element: <PointBalanceScreen />
            },
        },
        pointrecords: {
            index: {
                path: "/pointrecords",
                selectedKey: "pointrecords",
                needCache: true,
                element: <PointRecordsScreen />
            },
        },
    },
    systemconfig: {
        index: {
            path: "/systemconfig",
            selectedKey: "systemconfig",
            element: <SystemSettingScreen />
        },
    }
};

export const getRoutes = () => {
    let routers = Object.keys(MainLayoutUrl).map(key1 => {
        if(Object.keys(MainLayoutUrl[key1]).includes('index')) {
            return Object.keys(MainLayoutUrl[key1]).map(key2 => {
                return {
                    path: MainLayoutUrl[key1][key2].path,
                    key:  MainLayoutUrl[key1][key2].key ? MainLayoutUrl[key1][key2].key : MainLayoutUrl[key1][key2].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2].element,
                    needCache: MainLayoutUrl[key1][key2].needCache ? MainLayoutUrl[key1][key2].needCache : false,
                }
            })
        }
        return Object.keys(MainLayoutUrl[key1]).map(key2 => {
            return Object.keys(MainLayoutUrl[key1][key2]).map(key3 => {
                return {
                    path: MainLayoutUrl[key1][key2][key3].path,
                    key:  MainLayoutUrl[key1][key2][key3].key ? MainLayoutUrl[key1][key2][key3].key : MainLayoutUrl[key1][key2][key3].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2][key3].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2][key3].element,
                    needCache: MainLayoutUrl[key1][key2][key3].needCache ? MainLayoutUrl[key1][key2][key3].needCache : false,
                }
            });
        }).reduce((x, y) => [...(x ? x : []), ...y]);
    }).reduce((x, y) => [...(x ? x : []), ...y]);
    return routers;
}

const SystemRouters = () => {
    return (
        <HashRouter>
            <Routes>
                <Route
                    path="/login"
                    exact={true}
                    element={
                        <LoginRoute>
                            <LoginPanel />
                        </LoginRoute>
                    }
                />
                <Route path='/' element={<MainLayout />}>
                    <Route path='/' element={<BasicLayoutWithCache />}>
                        {
                            getRoutes().map((route) => <Route
                                key={route.key}
                                path={route.path}
                                exact={route.exact}
                                element={<PrivateRoute>{route.element}</PrivateRoute>}
                            />)
                        }
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />
                    }
                />
            </Routes>
        </HashRouter>
    );
};

const LoginRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    if (!isLoggedIn) {
        return <>{children}</>;
    }
    return <Navigate replace={true} to="/" />;
};

const PrivateRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getCookie("user-token") ? true : false;
    const location = useLocation();
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    );
};

const BasicLayoutWithCache = () => {
    const outlet = useOutlet();
    const location = useLocation();

    const currRouter = useMemo(() => {
        let _router = getRoutes().find(item => {
            if (location.pathname.indexOf('/') !== location.pathname.lastIndexOf('/') && item.path.indexOf('/') !== item.path.lastIndexOf('/')) {
                return location.pathname.split('/')[1] === item.path.split('/')[1]
            }
            return item.path === location.pathname;
        });
        return _router
    }, [location]);

    if (!currRouter) {
        return <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />;
    }

    return <div>
        <KeepAlive activeName={currRouter?.key} max={10} strategy={'LRU'} cache={currRouter?.needCache ?? false}>
            {outlet}
        </KeepAlive>
    </div>
}

export default SystemRouters;
