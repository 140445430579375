import { useParams, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useMemo } from 'react';
import '../../style/discover.css';
import { Button, Input, Select, Descriptions, Tabs, Space, Avatar, Spin, Typography } from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { CloudStorageImageUploader, CloudStorageVideoUploader } from "../../components/upload";
import { MainLayoutUrl } from "../../router";
import { createDiscover, updateDiscover } from "../../api/discover";
import ProjectForm from "../../components/discover/project_form";
import UgcForm from "../../components/discover/ugc_form";
import AdsForm from "../../components/discover/ads_form";
import ProjectPlainForm from "../../components/discover/project_plain_form";
import CourseForm from "../../components/discover/course_form";
import { useDebouncedCallback } from "use-debounce";
import { fetchUserSearch } from "../../api/community";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";

export const VideoCategoryColors = Object.freeze({
    UGC: 'magenta',
    ADS: 'green',
    Project: 'cyan',
    ProjectPlain: 'geekblue',
    VideoCourse: 'purple'
})

export const VideoCategoryOptions = Object.freeze([
    { key: '1', value: 'UGC', label: 'UGC' },
    { key: '2', value: 'ADS', label: 'ADS' },
    { key: '3', value: 'Project', label: 'Project' },
    { key: '4', value: 'ProjectPlain', label: 'Project Plain' },
    { key: '5', value: 'VideoCourse', label: 'Video Course' },
]);

const DiscoverTemplate = {
    title: null,
    content: null,
    resources: [],
    tags: [],
    video: {
        projectLogo: null,
        projectName: null,
        link: null,
        intro: null,
        section1: null,
        section2: null,
        section3: null,
        courseId: null,
        anchor1Text: null,
        anchor2Text: null,
        anchor3Text: null,
        bannerUrl: null,
    },
    discover: null,
    videoType: null,
    userId: null,
};

const DiscoverDetailScreen = (props) => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const [users, setUsers] = useState([]);
    const defaultData = (location.state && location.state.detail ? JSON.parse(location.state.detail) : DiscoverTemplate)
    const [data, setData] = useState(defaultData);
    const [category, setCategory] = useState(defaultData.videoType);
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const tabItems = () => {
        return VideoCategoryOptions.filter(item => item.value !== 'UGC').map((item) => {
            let children = <div />;
            if (item.value === 'Project') {
                children = <ProjectForm
                    defaultData={data.video}
                    onUpdate={value => onFormChange('video', value)}
                />;
            } else if (item.value === 'UGC') {
                children = <UgcForm
                    defaultData={data.video}
                    onUpdate={value => onFormChange('video', value)}
                />;
            } else if (item.value === 'ADS') {
                children = <AdsForm
                    defaultData={data.video}
                    onUpdate={value => onFormChange('video', value)}
                />;
            } else if (item.value === 'ProjectPlain') {
                children = <ProjectPlainForm
                    defaultData={data.video}
                    onUpdate={value => onFormChange('video', value)}
                />;
            } else if (item.value === 'VideoCourse') {
                children = <CourseForm
                    defaultData={data.video}
                    onUpdate={value => onFormChange('video', value)}
                />;
            }
            return {
                label: item.label,
                key: item.value,
                children: children,
            }
        })
    }

    useEffect(() => {
        if (defaultData.id) {
            let user = defaultData.user;
            fetchUsers(user ? (user.name ? user.name : user.email) : '');
        } else {
            fetchUsers('');
        }
    }, [])

    const handleSearch = (value) => {
        fetchUsers(value);
    }

    const fetchUsers = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
            }
            setSearchLoading(true);
            fetchUserSearch(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        avatar: (item.picture ? item.picture : item.avatar),
                        email: item.email,
                        label: item.name,
                        value: item.userId,
                    }));
                    setUsers(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onVideoUpdate = (files) => {
        if (!files || files.length <= 0) return;
        let resources = [...data.resources];
        let index = resources.findIndex(item => (item.includes('.mp4') || item.includes('.mp3')));
        if (index >= 0) {
            resources[index] = files[0].remoteUrl;
        } else {
            resources.unshift(files[0].remoteUrl);
        }
        let updated = { ...data, resources: resources };
        setData(updated);
    }

    const onImageUpdate = (files, flag) => {
        if (Array(files).length <= 0) return;
        let url = files[0].remoteUrl;
        if (flag === 'thumbnail') {
            let resources = [...data.resources];
            let index = resources.findIndex(item => (item.includes('.jpg') || item.includes('.png') || item.includes('.jpeg') || item.includes('.webp') || item.includes('.gif')));
            if (index >= 0) {
                resources[index] = url;
            } else {
                resources.push(url);
            }
            let updated = { ...data, resources: resources };
            setData(updated);
        }
    }

    const onFormChange = (key, value) => {
        let updated = { ...data };
        updated[key] = value;
        setData(updated);
        if (key === 'videoType') {
            setCategory(value);
        }
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data };
        if (params.id) {
            updateDiscover(params).then(res => {
                navigateTo(MainLayoutUrl.discover.index.path);
            }).finally(() => setLoading(false));
        } else {
            createDiscover(params).then(res => {
                navigateTo(MainLayoutUrl.discover.index.path);
            }).finally(() => setLoading(false));
        }
    }

    const toCancel = () => {
        navigateTo(MainLayoutUrl.discover.index.path);
    }

    const thumbnailUploadProps = {
        name: 'thumbnail-uploader',
        bucketPath: '/thumbnail',
        defaultFileList: useMemo(() => {
            let thumbnailUrl = data.resources.find(item => (item.includes('.jpg') || item.includes('.png') || item.includes('.jpeg') || item.includes('.webp') || item.includes('.gif')))
            return (thumbnailUrl ? [{
                name: (decodeURIComponent(thumbnailUrl).split('/').pop() ?? '').split('?')[0],
                url: thumbnailUrl,
                percent: 100,
            }] : []);
        }, [data.resources]),
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Thumbnail'),
        onUpdate: (data) => onImageUpdate(data, 'thumbnail'),
    }

    return <div style={{ padding: '20px 40px' }}>
        <div className="title">{id ? (id === '0' ? 'Create New Discover' : 'Edit Discover') : ''}</div>
        <div style={{ height: 30 }} />
        <Descriptions title='Basic Info' bordered size='large' column={2} labelStyle={{ width: 200 }}>
            <Descriptions.Item label='Video Title' span={2}>
                <Input value={data.title} placeholder="video title" onChange={(evt) => onFormChange('title', evt.target.value)} allowClear />
            </Descriptions.Item>
            <Descriptions.Item label='Video Category' span={1}>
                <Select
                    value={data.videoType}
                    style={{ width: '100%' }}
                    placeholder="video category"
                    onChange={val => onFormChange('videoType', val)}
                    options={VideoCategoryOptions} />
            </Descriptions.Item>
            <Descriptions.Item label='Recommend Weight' span={1}>
                <Input value={data.discover} placeholder="recommend weight" onChange={(evt) => onFormChange('discover', evt.target.value)} type="number" allowClear />
            </Descriptions.Item>
            <Descriptions.Item label='Video Tags' span={2}>
                <Select
                    value={data.tags.filter(item => item)}
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="video tags"
                    onChange={value => onFormChange('tags', value)}
                    options={[]}
                />
            </Descriptions.Item>
            <Descriptions.Item label='Video Resource' span={2}>
                <CloudStorageVideoUploader
                    defaultVideo={data.resources[0] ? data.resources[0] : ''}
                    name={'discover_uploader'}
                    bucketPath={'/discover'}
                    onUpdate={onVideoUpdate} />
            </Descriptions.Item>
            <Descriptions.Item label='Video Thumbnail' span={2}>
                <CloudStorageImageUploader
                    {...thumbnailUploadProps}
                />
            </Descriptions.Item>
            <Descriptions.Item label='Video Description' span={2}>
                <Input.TextArea value={data.content} placeholder="video description" onChange={(evt) => onFormChange('content', evt.target.value)} allowClear />
            </Descriptions.Item>
            <Descriptions.Item label='Related User' span={2}>
                <Select
                    style={{ width: '100%' }}
                    value={data.userId ? data.userId : (data.user ? data.user.userId : '')}
                    placeholder='search user'
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={searchLoading ? <Spin /> : null}
                    options={users}
                    optionRender={(option) => {
                        return (
                            <Space>
                                {
                                    option.data.avatar ?
                                        <Avatar size={32} src={option.data.avatar} />
                                        :
                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                }
                                <div>
                                    <b>{option.data.label ? option.data.label : 'anonymous'}</b>
                                    <div>
                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>{option.data.email ?? option.data.value}</Typography.Text>
                                    </div>
                                </div>
                            </Space>
                        )
                    }}
                    onChange={value => onFormChange('userId', value)}
                    allowClear
                    showSearch
                />
            </Descriptions.Item>
        </Descriptions>
        {
            (category && category !== 'UGC') && <div style={{ marginTop: 30 }}>
                <Tabs activeKey={data.videoType ? data.videoType : defaultData.videoType} items={tabItems()} onChange={val => onFormChange('videoType', val)} />
            </div>
        }
        <div className="btn-area">
            <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
            <div style={{ width: 24 }} />
            <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={toCancel}>Cancel</Button>
        </div>
    </div>
}

export default DiscoverDetailScreen;