import {  Select, Spin, Space, Image } from 'antd';
import { useState, useMemo, useEffect } from 'react';
import { Descriptions } from 'antd';
import { CloudStorageImageUploader } from '../upload';
import { PlusOutlined } from '@ant-design/icons';
import '../../style/sto.css';
import { getCourseCatalogs } from '../../api/course';
import { useDebouncedCallback } from 'use-debounce';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const CourseForm = ({ defaultData, onUpdate }) => {
    const [data, setData] = useState(defaultData ?? {});
    const [catalogs, setCatalogs] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const onFormChange = (flag, value) => {
        let updated = { ...data };
        updated[flag] = value;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }

    const onImageUpdate = (flag, files) => {
        if (Array(files).length <= 0) return;
        let updated = { ...data };
        updated[flag] = files[0].remoteUrl ? files[0].remoteUrl : files[0].url;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }

    const logoUploadProps = {
        name: 'logo-uploader',
        bucketPath: '/sto/logo',
        defaultFileList: useMemo(() => {
            return data.projectLogo ? [{
                name: (decodeURIComponent(data.projectLogo).split('/').pop() ?? '').split('?')[0],
                url: data.projectLogo,
                percent: 100,
            }] : [];
        }, [data.projectLogo]),
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Logo'),
        onUpdate: (data) => onImageUpdate('projectLogo', data),
    }

    useEffect(() => {
        if (defaultData) {
            getCatalogs(defaultData ? defaultData.title : '');
        } else {
            getCatalogs('');
        }
    }, [])

    const handleSearch = (value) => {
        getCatalogs(value);
    }

    const getCatalogs = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'title': keyword,
            }
            setSearchLoading(true);
            getCourseCatalogs(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        label: item.title,
                        value: item.id,
                        img: item.previewImage,
                    }));
                    setCatalogs(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div className='sto-wrapper' style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <div style={{ height: 20 }} />
            <Descriptions title='Other Info' size='large' bordered column={2} labelStyle={{ width: 200 }}>
                <Descriptions.Item label='Course Link' span={2}>
                    <Select
                        style={{ width: '100%' }}
                        value={data.courseId ? data.courseId : null}
                        placeholder='search course'
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={handleSearch}
                        notFoundContent={searchLoading ? <Spin /> : null}
                        options={catalogs}
                        optionRender={option => {
                            return (
                                <Space>
                                    <Image style={{width: 100, height: 60, objectFit: 'cover'}} src={option.data.img} />
                                    <div>{option.label}</div>
                                </Space>
                            )
                        }}
                        onChange={value => onFormChange('courseId', value)}
                        allowClear
                        showSearch
                    />
                </Descriptions.Item>
                <Descriptions.Item label='Project Logo' span={2}>
                    <CloudStorageImageUploader {...logoUploadProps} />
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default CourseForm;