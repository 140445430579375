import React, { useEffect, useState } from "react";
import '../../style/login.css';
import { Navigate } from 'react-router-dom';
import { getQueryString } from '../../utils/comm';
import { setCookie } from '../../utils/cookie';
import { getAuth } from 'firebase/auth'
import { customSignInWithEmailAndPassword, firebaseAuthApp } from "../../firebase/auth";
import BackgroundImage from '../../assets/gobg.jpg';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const LoginPanel = (props) => {
    const [username, setUsername] = useState('');
    const [needRedirect, setNeedRedirect] = useState(false);

    const redirectPath = getQueryString('redirect');

    useEffect(() => {
        const uiConfig = {
            signInFlow: "popup",
            signInOptions: [
                // Leave the lines as is for the providers you want to offer your users.
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                    setCookie('user-token', authResult.user.accessToken);
                    window.localStorage.setItem('rjwt', authResult.user.refreshToken);

                    setUsername(authResult.user.email);
                    return false;
                },
            },
        };
        const auth = getAuth(firebaseAuthApp());
        const ui = new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', uiConfig);

        // customSignInWithEmailAndPassword();
    }, [])

    useEffect(() => {
        if (username) {
            setNeedRedirect(true);
        }
    }, [username]);

    return (
        <div className="main" style={{ backgroundImage: `url(${BackgroundImage})` }}>
            <div className="login-form">
                <h1>Addx Go Admin</h1>
                <div className="head">
                    <img src='/images/logo.png' alt="" />
                </div>
                <div id="firebaseui-auth-container" />
                <div style={{ height: 12 }} />
            </div>
            {
                needRedirect ? <Navigate to={`/${redirectPath ? redirectPath : ''}`} props={{ account: { name: username } }} replace={true} /> : null
            }
        </div>
    );
}

export default LoginPanel;