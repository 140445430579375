import React from "react";
import {
    AppstoreOutlined,
    GlobalOutlined,
    CarryOutOutlined,
    BookOutlined,
    InstagramOutlined,
    UserOutlined,
    HomeOutlined,
    CommentOutlined,
    UserSwitchOutlined,
    QuestionCircleOutlined,
    HighlightOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    TagsOutlined,
    DashboardOutlined,
    ControlOutlined,
    IssuesCloseOutlined,
    MessageOutlined,
    StarFilled,
    ProductOutlined,
    TrademarkCircleOutlined,
    SolutionOutlined,
    SnippetsOutlined,
    FlagOutlined,
    ReconciliationOutlined,
    HolderOutlined,
    ProfileOutlined,
    ReadOutlined,
    FileTextOutlined,
} from "@ant-design/icons";
import '../style/layout.css';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { getRoutes, MainLayoutUrl } from '../router';
import { Avatar, Popover, Button, Layout, Menu, ConfigProvider, Space } from "antd";
import { deleteCookie } from '../utils/cookie';
import { Breadcrumb } from "antd/es";
import { Typography } from "antd";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const MainLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const items = [
        getItem('Dashboard', 'dashboard', <DashboardOutlined />),
        getItem('Banners', 'banner', <AppstoreOutlined />),
        getItem('Homepage', 'homepage', <HomeOutlined />, [
            getItem('Hot Article', 'hotarticle', <SnippetsOutlined />),
            getItem('Hot Topic', 'hottag', <FlagOutlined />),
        ]),
        getItem('Discover', 'discover', <InstagramOutlined />),
        getItem('Daily', 'daily', <CarryOutOutlined />, [
            getItem('Daily Knowledge', 'knowledge', <HighlightOutlined />),
            getItem('Daily Q&A', 'question', <QuestionCircleOutlined />),
        ]),
        getItem('Course', 'course', <BookOutlined />),
        getItem('Platform News', 'news', <GlobalOutlined />),
        getItem('Community', 'community', <CommentOutlined />, [
            getItem('Topic Tags', 'tags', <HolderOutlined />),
            getItem('Content Tags', 'clabels', <ProfileOutlined />),
            getItem('Posts', 'post', <FileTextOutlined />, [
                getItem('Admin Posts', 'adminpost'),
                getItem('User Posts', 'userpost'),
            ]),
            getItem('Articles', 'article', <ReadOutlined />, [
                getItem('Admin Articles', 'adminarticle'),
                getItem('User Articles', 'userarticle'),
            ]),
            getItem('Bounty Topic', 'topic', <TagsOutlined />),
            getItem('Comment', 'comment', <MessageOutlined />),
            getItem('Report Case', 'report', <IssuesCloseOutlined />),
        ]),
        getItem('Users', 'users', <TeamOutlined />, [
            getItem('All', 'all', <UsergroupAddOutlined />),
            getItem('Recommended', 'recommenduser', <UserSwitchOutlined />),
            getItem('User Labels', 'ulabels', <SolutionOutlined />),
        ]),
        getItem('Task Center', 'points', <StarFilled />, [
            getItem('Products', 'products', <ProductOutlined />),
            getItem('Point Balance', 'balance', <TrademarkCircleOutlined />),
            getItem('Point Records', 'pointrecords', <ReconciliationOutlined />),
        ]),
        getItem('System Config', 'systemconfig', <ControlOutlined />),
    ];

    const breadItems = () => {
        let paths = getActivedPaths(null, items, defaultKey());
        const routes = getRoutes();
        let arrs = paths.map(item => {
            let route = routes.find(rt => rt.key === item.key);
            if (route) {
                return { title: item.label, path: route.path }
            }
            return { title: item.label }
        });

        return [{ title: <HomeOutlined /> }, ...arrs];
    };

    const menuChangeHandle = (evt) => {
        let paths = evt.keyPath;
        const totalRoutes = getRoutes();
        navigate(totalRoutes.find(item => item.key === paths[0]).path);
    }

    const logoutHandle = () => {
        deleteCookie('user-token');
        window.localStorage.removeItem('rjwt');
        window.location.reload();
    }

    const defaultKey = () => {
        const pathArr = location.pathname.split('/');
        if (!isNaN(pathArr[pathArr.length - 1])) {
            pathArr[pathArr.length - 1] = ':id'
        }
        let pathname = pathArr.join('/');
        const routes = getRoutes();
        const route = routes.find(item => pathname.includes(item.path));
        if (route) {
            return route.selectedKey;
        }
        return '';
    };

    const defaultOpenKey = () => {
        let keys = getActivedPaths(null, items, defaultKey()) ?? [];
        return keys.map(item => item.key);
    }

    const getActivedPaths = (parentKey, routeArr, pathKey) => {
        let keys = [];
        for (let i = 0; i <= routeArr.length - 1; i++) {
            let route = routeArr[i];
            let data = { key: route.key, label: route.label }
            if (route.key === pathKey) {
                keys = parentKey ? [parentKey, data] : [data];
                break;
            } else if (route.children && route.children.length > 0) {
                const chKeys = getActivedPaths(data, route.children, pathKey);
                if (chKeys.length > 0) {
                    keys = parentKey ? [parentKey, ...keys, ...chKeys] : [...keys, ...chKeys];
                    break;
                }
            }
        }
        return keys;
    }

    const AccountPanel = () => {
        return (
            <div>
                <Button onClick={logoutHandle} type="text">Sign out</Button>
            </div>
        );
    }

    return (
        <ConfigProvider theme={{
            token: {
                colorTextDisabled: '#666',
                colorBgContainerDisabled: '#f5f5f5'
            },
            components: {
                Menu: {
                    darkItemSelectedBg: 'rgba(255, 255, 255, 0.15)'
                },
                Layout: {
                    headerBg: '#002766',
                },
                Button: {
                    borderRadius: 0
                }
            }
        }}>
            <Layout style={{ height: '100vh' }}>
                <Header className="wrapper-header" style={{ padding: 0 }}>
                    <div className="inc-header">
                        <img src="/images/logo.png" style={{ width: 36, height: 36, marginRight: 8, borderRadius: 20 }} />
                        Go CMS
                    </div>
                    <div style={{ marginRight: 20 }}>
                        <Popover trigger={'click'} placement="bottomRight" content={AccountPanel}>
                            <Space size={4}>
                                <Avatar size={24} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                <Typography.Text style={{ color: 'white' }}>Hello, Admin</Typography.Text>
                            </Space>
                        </Popover>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        theme="dark"
                        width={240}
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                        style={{ overflowY: 'auto' }}
                    >
                        <div style={{ height: 24 }} />
                        <Menu
                            items={items}
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[defaultKey()]}
                            defaultOpenKeys={defaultOpenKey()}
                            onClick={menuChangeHandle}
                        />
                    </Sider>
                    <Layout>
                        <Breadcrumb style={{ margin: '16px 16px 0 16px' }} items={breadItems()} />
                        <Content style={{ margin: '24px 16px 0', overflowY: 'auto' }} className={location.pathname === MainLayoutUrl.dashboard.index.path ? '' : 'site-layout-background'}>
                            <Outlet />
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>Go Wallet©2024 Created by ADDX</Footer>
                    </Layout>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default MainLayout;