import { Input } from 'antd';
import { useState, useMemo } from 'react';
import { Descriptions } from 'antd';
import { CloudStorageImageUploader } from '../upload';
import { PlusOutlined } from '@ant-design/icons';
import '../../style/sto.css';
import RichTextEditor from '../editor';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const ProjectPlainForm = ({ defaultData, onUpdate }) => {
    const [data, setData] = useState(defaultData ?? {});

    const onFormChange = (flag, value) => {
        let updated = { ...data };
        updated[flag] = value;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }

    const onImageUpdate = (flag, files) => {
        if (Array(files).length <= 0) return;
        let updated = { ...data };
        updated[flag] = files[0].remoteUrl ? files[0].remoteUrl : files[0].url;
        setData(updated);
        if (onUpdate) onUpdate(updated);
    }

    const logoUploadProps = {
        name: 'logo-uploader',
        bucketPath: '/sto/logo',
        defaultFileList: useMemo(() => {
            return data.projectLogo ? [{
                name: (decodeURIComponent(data.projectLogo).split('/').pop() ?? '').split('?')[0],
                url: data.projectLogo,
                percent: 100,
            }] : [];
        }, [data.projectLogo]),
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Logo'),
        onUpdate: (data) => onImageUpdate('projectLogo', data),
    }

    const bannerUploadProps = {
        name: 'banner-uploader',
        bucketPath: '/sto/banner',
        defaultFileList: useMemo(() => {
            return data.bannerUrl ? [{
                name: (decodeURIComponent(data.bannerUrl).split('/').pop() ?? '').split('?')[0],
                url: data.bannerUrl,
                percent: 100,
            }] : [];
        }, [data.bannerUrl]),
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Banner'),
        onUpdate: (data) => onImageUpdate('bannerUrl', data),
    }

    return (
        <div className='sto-wrapper' style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <div style={{ height: 20 }} />
            <Descriptions title='Other Info' size='large' bordered column={2} labelStyle={{ width: 200 }}>
                <Descriptions.Item label='Project Name' span={2}>
                    <Input placeholder="project name" value={data.projectName} onChange={(evt) => onFormChange('projectName', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Project Link' span={2}>
                    <Input placeholder="project link" value={data.link} onChange={(evt) => onFormChange('link', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Project Logo' span={2}>
                    <CloudStorageImageUploader {...logoUploadProps} />
                </Descriptions.Item>
                <Descriptions.Item label='Banner' span={2}>
                    <CloudStorageImageUploader {...bannerUploadProps} />
                </Descriptions.Item>
                <Descriptions.Item label='Intro' span={2}>
                    <RichTextEditor
                        contentField={'intro'}
                        defaultContent={data.intro}
                        defaultHeight={300}
                        onUpdate={onFormChange} />
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default ProjectPlainForm;