import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Popconfirm, Image, Tag } from 'antd';
import { PlusOutlined, ForwardOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { deleteCatalogs, getCourseCatalogs, getCourseCategories } from '../../api/course';
import CategoriesView from '../../components/course/categories_view';
import { Flex } from 'antd';
import CatalogEditView from '../../components/course/catalog_edit_view';
import '../../style/course.css';
import ChaptesEditView from '../../components/course/chapters_edit_view';

const CategoryColorsSets = [
    'magenta', 'green', 'cyan', 'geekblue', 'blue', 'purple'
];

const CourseScreen = (props) => {
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Catalog Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (text, _) => {
                if (!categories || categories.length <= 0) return '';
                let index = Array.from(categories).findIndex((item) => item.id === text || item.children.map(ch => ch.id).includes(text));
                if (index < 0) return '';
                let chIndex = categories[index].children.findIndex(item => item.id === text);
                if (chIndex < 0) {
                    return <Tag color={CategoryColorsSets[index % 6]}>{categories[index].name}</Tag>
                }
                return (
                    <Flex>
                        <Tag color={CategoryColorsSets[index % 6]}>
                            {categories[index].name}
                        </Tag>
                        <ForwardOutlined style={{ marginRight: 8 }} />
                        <Tag color={CategoryColorsSets[index % 6]}>
                            {categories[index].children[chIndex].name}
                        </Tag>
                    </Flex>

                )
            }
        },
        {
            title: 'Chapters Count',
            dataIndex: 'chapterCount',
            key: 'chapterCount',
            render: (_, record) => {
                let count = (record.chapters ?? []).length;
                if (parseInt(count) <= 0) return (
                    <Button type='dashed' size='small' style={{ fontSize: 12 }} onClick={() => toCatalog(record)}> + Add New Chapter</Button>
                )
                return (
                    <div style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => toCatalog(record)}>
                        <b className='course-static'>{count}</b> chapters
                    </div>
                )
            }
        },
        {
            title: 'Is Recommended',
            dataIndex: 'recommend',
            key: 'recommend',
            render: (text, _) => {
                if(text) return 'Yes';
                return '-'
            }
        },
        {
            title: 'Is Banner',
            dataIndex: 'banner',
            key: 'banner',
            render: (text, _) => {
                if(text) return 'Yes';
                return '-'
            }
        },
        {
            title: 'Learner Count',
            dataIndex: 'learningCount',
            key: 'learningCount',
            render: (text, _) => {
                if(text && parseInt(text) > 0) return text;
                return '-'
            }
        },
        {
            title: 'Thumbnail',
            dataIndex: 'previewImage',
            key: 'previewImage',
            render: (text, _) => {
                if (!text) return <div>-</div>;
                if (!String(text).includes('https://')) {
                    source = `https://${text}`;
                }
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Image src={text} style={{ maxWidth: 120, maxHeight: 60 }} />
                    </div>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' loading={record.isLoading} onClick={() => { editCourse(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this catalog?"
                            onConfirm={() => { deleteCourse(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link' loading={record.isLoading}>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [categories, setCategories] = useState([]);
    const [catalogs, setCatalogs] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [catalogDrawerOpen, setCatalogDrawerOpen] = useState(false);
    const [chaptersDrawerOpen, setChaptersDrawerOpen] = useState(false);
    const [curCatalog, setCurCatalog] = useState();
    const [lastChapterId, setLastChapterId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, window.sessionStorage.getItem('tmp-catalog'), window.sessionStorage.getItem('tmp-chapter'));
        setLastChapterId(window.sessionStorage.getItem('tmp-chapter'));
        window.sessionStorage.removeItem('tmp-catalog');
        window.sessionStorage.removeItem('tmp-chapter');
    }, []);

    useEffect(() => {
        if (!catalogDrawerOpen) {
            setCurCatalog(null);
        }
    }, [catalogDrawerOpen])

    useEffect(() => {
        if (!chaptersDrawerOpen) {
            setCurCatalog(null);
        }
    }, [chaptersDrawerOpen])

    const fetchData = async (pNum, pSize, targetCatalogId = null, targetChapterId = null) => {
        setLoading(true);
        let results = await Promise.all([
            getCourseCategories({ 'page.disable': true, 'parentId': 0 }),
            getCourseCatalogs({ 'page.num': pNum, 'page.size': pSize }),
        ]);
        setLoading(false);
        if (results[0].list) {
            setCategories(results[0].list.map(item => {
                let newItem = { ...item };
                newItem.isEditing = false;
                newItem.isLoading = false;
                newItem.selectedIds = [];
                return newItem;
            }));
        }
        if (results[1].list && results[1].page) {
            let catalogs = results[1].list.map(item => {
                let newItem = { ...item };
                newItem.isLoading = false;
                newItem.dataLoaded = false;
                return newItem;
            });
            if (targetCatalogId) {
                setCurCatalog(catalogs.find(item => item.id === targetCatalogId));
                if (targetChapterId) {
                    setChaptersDrawerOpen(true);
                }
            }
            setCatalogs(catalogs);
            setTotal(parseInt(results[1].page.total));
        }
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewCourse = () => {
        setCurCatalog(null)
        setCatalogDrawerOpen(true);
    }

    const editCourse = (item) => {
        setCurCatalog(item);
        setCatalogDrawerOpen(true);
    }

    const deleteCourse = (item) => {
        item.isLoading = true;
        setCatalogs([...catalogs]);
        deleteCatalogs([item.id]).then(res => {
            fetchData(pageNum, pageSize);
        });
    }

    const showAllCategories = () => {
        setModalOpen(true);
    }

    const toCatalog = (catalog) => {
        setCurCatalog(catalog);
        setChaptersDrawerOpen(true);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewCourse}>Create New Catalog</Button>
                    <Button icon={<UnorderedListOutlined />} onClick={showAllCategories}>All Categories</Button>
                </div>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={catalogs} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <CategoriesView
                open={modalOpen}
                defaultCategories={categories}
                onClose={() => setModalOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize)}
            />
            <CatalogEditView
                categories={categories}
                open={catalogDrawerOpen}
                defaultCatalog={curCatalog}
                onClose={() => setCatalogDrawerOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize)}
            />
            <ChaptesEditView
                defaultActiveKey={lastChapterId}
                defaultCatalog={curCatalog}
                open={chaptersDrawerOpen}
                onClose={() => setChaptersDrawerOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize)}
            />
        </div>
    )
}

export default CourseScreen;