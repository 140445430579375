import { firebaseConfig, BucketType } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Progress, Upload, message, Typography } from "antd";
import { useState } from "react";
import { CheckCircleFilled, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { getVideoOutputBucket } from '../../utils/base_url';

const CloudStorageVideoUploader = ({
    name,
    bucketPath,
    defaultVideo,
    onUpdate,
}) => {
    const [fileList, setFileList] = useState(defaultVideo ? [{
        name: decodeURIComponent(defaultVideo.split('/')[defaultVideo.split('/').length - 1]),
        percent: 100,
        remoteUrl: defaultVideo.videoUrl,
    }] : []);

    const handleUpload = (options) => {
        let file = options.file;
        const storageRef = ref(firebaseConfig(BucketType.video), `${bucketPath}/${encodeURIComponent(file.name.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                let fileData = fileList.find((ele) => ele.uid === file.uid);
                fileData.percent = percent;
                setFileList([...fileList]);
            },
            (err) => {
                message.error(String(err));
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    let updated = url;
                    if (updated.includes('/o/')) {
                        updated = 'https://' + getVideoOutputBucket() + '/' + decodeURIComponent(updated.split('?')[0]).split('/o/')[1];
                    }
                    let lastDotIndex = String(updated).lastIndexOf('.');
                    updated = updated.substring(0, lastDotIndex) + '.1080' + updated.substring(lastDotIndex);
                    let fileData = fileList.find((ele) => ele.uid === file.uid);
                    fileData.remoteUrl = updated;
                    setFileList([...fileList]);
                    if (onUpdate) onUpdate([...fileList]);
                });
            }
        );
    }

    const onDelete = (fileUid) => {
        setFileList([])
        if (onUpdate) onUpdate([]);
    }

    const EllipsisMiddle = ({ suffixCount, children }) => {
        const start = children.slice(0, children.length - suffixCount);
        const suffix = children.slice(-suffixCount).trim();
        return (
            <Typography.Text style={{ maxWidth: '30vw' }} ellipsis={{ suffix, tooltip: children }} >
                {start}
            </Typography.Text>
        );
    }

    return (
        <div>
            <div className="video-part">
                {
                    fileList.length > 0 ?
                        <div className="title">
                            {
                                fileList[0].percent == 100 ?
                                    <CheckCircleFilled style={{ color: '#269f50', fontSize: 16 }} />
                                    :
                                    <Progress
                                        type="circle"
                                        trailColor="#e6f4ff"
                                        percent={fileList[0].percent}
                                        strokeWidth={16}
                                        size={16}
                                    />
                            }
                            <div className="text">
                                <EllipsisMiddle suffixCount={12}>{fileList[0].name.split('?')[0]}</EllipsisMiddle>
                            </div>
                            <CloseOutlined onClick={onDelete} style={{ color: '#afafaf', fontSize: 18, marginLeft: 8, cursor: 'pointer' }} />
                        </div>
                        :
                        <div className="title-tips">select video</div>
                }
                <Upload
                    name={name}
                    multiple={false}
                    showUploadList={false}
                    customRequest={handleUpload}
                    beforeUpload={(file, files) => {
                        let data = {
                            name: file.name,
                            uid: file.uid,
                            type: file.type,
                            size: Math.floor(file.size * 10 / 1024 / 1024) / 10,
                            percent: 0,
                            remoteUrl: '',
                            duration: '',
                            seconds: 0,
                        };
                        setFileList([data]);

                        let video = document.createElement('video');
                        video.src = (window.URL || window.webkitURL).createObjectURL(file);
                        video.oncanplay = () => {
                            let duration = video.duration;
                            let hour = parseInt(duration / 3600);
                            let minutes = parseInt(duration % 3600 / 60);
                            let seconds = parseInt(duration % 60);
                            let formatDuration = seconds > 0 ? `${seconds}s` : '';
                            if (minutes > 0) {
                                formatDuration = `${minutes}min${formatDuration}`;
                            }
                            if (hour > 0) {
                                formatDuration = `${hour}h${formatDuration}`;
                            }
                            data.duration = formatDuration;
                            data.seconds = duration;
                            setFileList([data]);
                        }
                        return file;
                    }}>
                    <Button style={{ height: 48 }} type="primary" icon={<PlusOutlined style={{ fontSize: 13 }} />}>Upload Video</Button>
                </Upload>
            </div>
            {
                fileList.length > 0 ?
                    (fileList[0].uid ?
                        <div className="video-result">
                            <div className="info">
                                <div className="text">
                                    <span className="t1">{fileList[0].percent == 100 ? 'Upload Successfully' : `Uploading ${fileList[0].percent}%...`}</span>
                                    <span className="t2">{fileList[0].name}</span>
                                    <span className="t2">Size: {fileList[0].size}M</span>
                                    <span className="t2">Duration: {fileList[0].duration}</span>
                                </div>
                                <Button type="link" danger onClick={onDelete}>Delete</Button>
                            </div>
                            <Progress showInfo={false} percent={fileList[0].percent} />
                        </div>
                        :
                        <div />)
                    :
                    <div />
            }

        </div>
    )
}
export default CloudStorageVideoUploader;