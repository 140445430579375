import { Button, Modal, Typography, Spin, message, Input } from "antd";
import { useEffect, useState } from "react";
import { createTag, deleteTag, fetchAllTags, updateTag } from "../../api/community";
import { LoadingOutlined } from "@ant-design/icons";
import ContentTagItemEditView from "./content_tag_item_edit_view";

const TagChildrenModalView = ({ defaultTag, onUpdate, onClose, open }) => {
    const [tags, setTags] = useState([]);
    const [isDiff, setIsDiff] = useState(false);
    const [loading, setLoading] = useState(false);
    const [oldTags, setOldTags] = useState([]);
    const [keywords, setKeywords] = useState(null);

    useEffect(() => {
        if (defaultTag && parseInt(defaultTag.id) > 0) {
            getData();
        }
    }, [defaultTag])

    const getData = () => {
        setLoading(true);
        let params = {
            'page.disable': true,
            'level': 1,
            'parentId': defaultTag.id,
        };
        fetchAllTags(params).then(res => {
            if (res.list) {
                setTags(JSON.parse(JSON.stringify(res.list)));
                setOldTags(JSON.parse(JSON.stringify(res.list)));
            }
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        let diffs = getDiffTags();
        setIsDiff(diffs.length > 0 ? true : false);

        if (diffs.findIndex(item => parseInt(item.id ?? '0') <= 0) >= 0) {
            toSubmit();
        }
    }, [tags])

    useEffect(() => {
        if (!open) {
            setKeywords(null);
        }
    }, [open])

    const getDiffTags = () => {
        let arr = Array.from(tags).filter(item => {
            if (!oldTags.map(old => old.name).includes(item.name)) {
                return true;
            }
            return false;
        });
        return arr;
    }

    const toSubmit = () => {
        setLoading(true);
        let chs = getDiffTags().map(item => ({
            id: (item.id && parseInt(item.id) > 0) ? item.id : undefined,
            level: 1,
            parentId: defaultTag.id,
            name: item.name
        }));
        let created = chs.filter(item => !item.id);
        let updated = chs.filter(item => item.id);
        let reqArr = [];
        if (created.length) {
            reqArr.push(createTag(created[0]));
        }
        if (updated.length) {
            reqArr.push(updateTag({ list: updated }))
        }
        Promise.all(reqArr).then(res => {
            getData();
            onUpdate();
        }).catch(err => {
            message.error(err.message);
            setLoading(false);
        })
    }

    const onDelete = (tagId) => {
        deleteTag(tagId).then(res => {
            getData();
            onUpdate();
        }).catch(err => {
            message.error(String(err));
        })
    }

    if (!defaultTag) return <div />
    return (
        <Modal
            centered open={open}
            title={
                <div style={{ color: '#afafaf' }}>
                    <Typography.Text mark style={{ fontSize: 16 }}><b>{`#${defaultTag.name}`}</b></Typography.Text>
                    &nbsp; - second level
                </div>
            }
            width={720}
            onCancel={() => {
                setTags([]);
                setOldTags([]);
                onClose();
            }}
            footer={[
                <Button style={{ width: 140 }} key={'diff'} onClick={toSubmit} disabled={!isDiff} loading={loading} type="primary">Save</Button>
            ]}>
            <div style={{ padding: '12px 0' }}>
                <Input
                    style={{ width: 240 }}
                    value={keywords}
                    placeholder="search tags"
                    onChange={(evt) => setKeywords(evt.target.value)} />
                <div style={{ height: 24 }} />
                {
                    loading && <div style={{ padding: '12px 0' }}><Spin indicator={<LoadingOutlined />} spinning /></div>
                }
                <ContentTagItemEditView
                    defaultTags={keywords ? tags.filter(item => item.name.includes(keywords)) : tags}
                    onUpdate={(newTags) => setTags(newTags)}
                    onDelete={onDelete}
                />
            </div>
        </Modal>
    )
}

export default TagChildrenModalView;