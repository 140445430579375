import React, { useEffect, useState } from 'react';
import { Skeleton, Flex, Drawer, Timeline } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Tag } from 'antd';
import { fetchPointsHistory } from '../../api/points';
import UserInfoView from '../community/user_info_view';
import { conversionUtcDate } from '../../utils/comm';

const PointsHistoryView = ({ user, open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [hasMore, setHasMore] = useState(true)
    const pageSize = 20;

    const fetchData = (page) => {
        let params = {
            'userId': user.userId,
            'page.num': page,
            'page.size': pageSize
        };
        fetchPointsHistory(params).then(res => {
            if (res.list) {
                let tmp = Array.from(res.list).map(item => {
                    return {
                        color: item.direction === 'In' ? 'green' : 'red',
                        children: (
                            <>
                                <div style={{fontSize: 12, color: '#afafaf'}}>{conversionUtcDate(item.createdAt, null, true)}</div>
                                <div style={{fontSize: 16, color: '#333', marginTop: 4}}>
                                    {item.activityType}: {item.direction === 'In' ? `+${item.point}` : `-${item.point}`}
                                </div>
                            </>
                        )
                    }
                });
                setData(page > 1 ? [...data, ...tmp] : tmp);
                if (Array.from(res.list).length < pageSize) {
                    setHasMore(false);
                    setPageNum(page > 1 ? page - 1 : 1);
                } else {
                    setHasMore(true);
                }
            }
        }).catch((err) => {
            setHasMore(false);
        }).finally(() => {
            setLoading(false);
        })
    };
    useEffect(() => {
        if (user) {
            setLoading(true);
            setPageNum(1);
            fetchData(1);
        }
    }, [user]);

    const loadMoreData = () => {
        setPageNum(pageNum + 1);
        fetchData(pageNum + 1);
    }

    const getTitle = () => {
        return (
            <Flex justify='space-between' align='center'>
                <div style={{ flex: 1, marginRight: 8 }}>
                    <UserInfoView showWallet={false} user={user.user} />
                </div>
                <div style={{ fontSize: 14, color: '#999', wordBreak: 'break-all' }}>Points History</div>
            </Flex>
        )
    }

    if (!user) return <div />
    return (
        <Drawer open={open} width={428} title={getTitle()} onClose={onClose}>
            {
                loading ?
                    <Spin />
                    :
                    <div
                        id="scrollableDiv"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                            padding: '0 16px',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={data.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={
                                <Skeleton
                                    avatar
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            <div style={{height: 8}} />
                            <Timeline items={data} />
                        </InfiniteScroll>
                    </div>
            }
        </Drawer>
    );
};
export default PointsHistoryView;